import React from 'react';
import {Link} from 'react-router-dom';
import FooterLogo from '../../assets/images/home/FooterLogo.webp';
import { ArrowRightCircleFill, GeoAltFill, TelephoneFill } from 'react-bootstrap-icons';

const Footer = () => {
  return (
    <>
    <section className="container-fluid bg-ss-darkgreen py-4 py-xl-5">
      <div className="row justify-content-evenly gy-4">
        <div className="col-8 col-lg-3 text-center">
          <div className=''>
          <img src={FooterLogo} alt="" className="w-50" />
            <div className='pt-3 pt-xl-4'>
            <a href="tel:+12567745524" className='text-decoration-none text-white'>
            256-774-5524
            </a>
            </div>
          </div>
        </div>
        <div className="col-11 col-lg-3">
          <div className='text-ss-primary mb-2 mb-xl-4 fw-600 fs-ss-22'>
            Quick Links
          </div>
          <div className='mb-2'>
            <ArrowRightCircleFill className='text-white me-2'/> 
            <Link to="/" className='text-decoration-none text-white'>
              Home & Office Hours
            </Link> 
          </div>
          <div className='mb-2'>
            <ArrowRightCircleFill className='text-white me-2'/> 
            <Link to="/" className='text-decoration-none text-white'>
              Providers & Staff
            </Link> 
          </div>
          <div className='mb-2'>
            <ArrowRightCircleFill className='text-white me-2'/> 
            <Link to="/" className='text-decoration-none text-white'>
              Services Offered
            </Link> 
          </div>
          <div className='mb-2'>
            <ArrowRightCircleFill className='text-white me-2'/> 
            <Link to="/" className='text-decoration-none text-white'>
              Directions & Contact Info
            </Link> 
          </div>
          <div className='mb-2'>
            <ArrowRightCircleFill className='text-white me-2'/> 
            <Link to="/" className='text-decoration-none text-white'>
              Health Links
            </Link> 
          </div>
          <div className='mb-2'>
            <ArrowRightCircleFill className='text-white me-2'/> 
            <Link to="/" className='text-decoration-none text-white'>
              Coronavirus Disease Updates
            </Link> 
          </div>
        </div>
        <div className="col-11 col-lg-3">
          <div className='text-ss-primary mb-2 mb-xl-4 fw-600 fs-ss-22'>
            Get in Touch
          </div>
          <div className='mb-2'>
            <TelephoneFill className='text-white me-2'/> 
            <a href="tel:+12567745524" className='text-decoration-none text-white'>
            256-774-5524
            </a>
          </div>
          <div className='mb-2'>
            <GeoAltFill className='text-white me-2'/> 
            <a href="https://www.google.com/maps/place/400+Sun+Temple+Dr,+Madison,+AL+35758,+USA/@34.7131481,-86.739948,17z/data=!3m1!4b1!4m6!3m5!1s0x88626f5f3ba43f51:0xe1a93e8a15d998cd!8m2!3d34.7131481!4d-86.739948!16s%2Fg%2F11rr8dz8jr?entry=ttu" target='_blank' className='text-decoration-none text-white'>
            New Office: 400 Sun <br/> Temple Dr, Madison, AL 35758
            </a>
          </div>
        </div>
      </div>
    </section>
    <section className="container-fluid py-1 bg-white fs-ss-14 text-black text-center">
      Copyright © 2023 Madison Primary Care. Powered by <a className='text-ss-primary' href="https://simplesolutionz.org/" target='_blank'> Simple Solutionz </a>
    </section>
    </>
  );
}

export default Footer;
