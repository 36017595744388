import React from 'react';
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom";

const NavBarLink = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <>
    <li className="nav-item fw-500 fs-ss-links pe-lg-2">
    <Link className={splitLocation[1] === `${props.Link_To}` ? "nav-link hover-sec text-ss-secondary" : "nav-link hover-sec"} to={`/${props.Link_To}`}> {props.Name} </Link>
    </li>
    </>
  )
}

export default NavBarLink