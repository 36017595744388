import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import CommonBanner from '../components/CommonBanner';
import BgImage from '../assets/images/home/HomeBanner.webp';
import Logo from '../assets/images/home/Logo.webp';
import ComprehensiveFamilyCare from '../assets/images/home/ComprehensiveFamilyCare.webp';
import AcceptingNewPatients from '../assets/images/home/AcceptingNewPatients.webp';
import {Link} from "react-router-dom";
import SingleService from '../components/common/SingleService';
import Vaccinations from '../assets/images/home/Vaccinations.webp';
import LaboratoryServices from '../assets/images/home/LaboratoryServices.webp';
import MinorSkinSurgeries from '../assets/images/home/MinorSkinSurgeries.webp';
import { ArrowRight, CircleFill } from 'react-bootstrap-icons';
import MiddleBanner from '../components/common/MiddleBanner';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
        Home - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <CommonBanner
    Image={BgImage}
    Title={<>
    We are your <br/>
    <span className='fw-500 d-block fs-ss-68'> Family </span>
    <span className='fw-800 fs-ss-68'> Physician </span>
    </>}
    />

    <section className='pt-4 pt-lg-5'/>

    <section id='website-responsive' className="container-fluid">
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-10">
          <div className=''>
            <img className='w-logo' src={Logo} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-35 fw-600 lh-sm'>
            Welcome to Madison Primary Care
            </div>
            <div className='pt-2'/>
            <div className='fs-ss-16 fw-400'>
            At Madison Primary Care, your well-being is our priority. Our family-oriented and highly skilled doctors provide various preventive, acute, and chronic care services. From immunizations to comprehensive annual physicals and mental health services, we're committed to addressing your health needs holistically. Our primary care physicians in Madison Al prioritize personalized care to build lasting relationships and enhance your health at every visit. 
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-lg-5'/>

    <section id='website-responsive' className="container-fluid text-center text-lg-start bg-ss-pink py-5">
      <div className="row gy-4 justify-content-evenly align-items-center">
        <div className="col-12 col-lg-5 text-center">
          <img className='w-75' src={ComprehensiveFamilyCare} alt="Comprehensive Family Care" /> 
        </div>
        <div className="col-11 col-lg-5">
        <div className=''>
            <div className='fs-ss-35 fw-800 lh-sm text-uppercase'>
            Comprehensive <br className='d-none d-lg-block'/> Family Care 
            </div>
            <div className='pt-2 pt-lg-4'/>
            <div className='fs-ss-16 fw-400'>
            At <strong> Madison Primary Care clinic </strong> in Huntsville, Alabama, our comprehensive family care goes beyond a typical doctor's visit. We're dedicated to assisting each patient in leading their healthiest life, offering medical aid, support, and motivation. <strong> From children to adults, </strong> our healthcare services cater to the entire family, aiming to steer you toward improved health with guidance and encouragement. 
            </div>
            <div className='pt-4'>
              <Link to="/book-now" className='text-decoration-none shadow-ss-1 py-2 fw-500 btn-primary-secondary border-0 text-white fs-ss-18 px-4 rounded-5'>
                Book Appointment
              </Link>
          </div>
          </div>
        </div>
      </div>
    </section>
    <section id='website-responsive' className="container-fluid">
      <div className="row gy-4 justify-content-center">
        <div className="col-11 col-lg-6 align-self-center">
        <div className='p-5 m-5'>
            <div className='fs-ss-35 fw-800 lh-sm text-uppercase'>
            Accepting <br className='d-none d-lg-block'/> New <span className='text-ss-primary'> Patients </span>  
            </div>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400'>
            We're delighted to welcome you into our healthcare family. Our qualified doctors in Madison, Alabama, are accepting new patients. Setting up an appointment is simple; call us at <a className='text-decoration-none fw-600 text-black' href="tel:+12567745524"> 256-774-5524 </a> and let us take care of the rest. 
            </div>
            <div className='pt-4'>
              <Link to="/book-now" className='text-decoration-none shadow-ss-1 py-2 fw-500 btn-primary-secondary border-0 text-white fs-ss-18 px-4 rounded-5'>
                New Patient Appointment
              </Link>
          </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 px-0">
          <img className='w-100' src={AcceptingNewPatients} alt="Accepting New Patients" /> 
        </div>
      </div>
      </section>
      
      <section className='pt-4 pt-lg-5'/>

      <section id='website-responsive' className="container-fluid">
        <div className='pb-4 ps-5 ms-5 d-flex'>
          <div className=''>
        <div className='fs-ss-35 fw-700 lh-sm'>
          Our Services
        </div>
        <div className='fs-ss-24 fw-ss-500'>
          Holistic Healing, Lifelong Wellness
        </div>
        </div>
        <div className='ms-auto pe-5 me-5 align-self-center'>
          <Link to="/book-now" className='text-decoration-none shadow-ss-1 py-2 fw-500 btn-primary-gradient border-0 text-white fs-ss-18 px-3 rounded-5'>
            View All <ArrowRight className='ms-3 fw-800 text-black'/>
          </Link>
        </div>
        </div>
      
        <div className="row justify-content-evenly">
        <SingleService
          Image={Vaccinations}
          Name="Vaccinations"
          Alt="Vaccinations"
          />
          <SingleService
          Image={LaboratoryServices}
          Name={<>In-house <br/> Laboratory Services </>}
          Alt="Laboratory Services"
          />
          <SingleService
          Image={MinorSkinSurgeries}
          Name="Minor Skin Surgeries"
          Alt="Minor Skin Surgeries"
          />
        </div>
      </section>
      
      <section className='pt-4 pt-lg-5'/>

      <MiddleBanner
      Title="Your Health, Our Lifelong Commitment "
      Para={<>
      <strong> Dr. Bodla: Your Lifelong Health Partner. </strong> Committed to forging enduring connections, Dr. Bodla prioritizes understanding your health journey and making informed choices collaboratively. More than a physician, he's your professional family doctor in Madison al, a trusted guide toward a healthier life. 
      </>}
      />
      
      <section className='pt-4 pt-lg-5'/>

      <section id='website-responsive' className="container-fluid bg-ss-pink py-5">
        <div className='pb-2 ps-5 ms-5 d-flex'>
          <div className=''>
          <div className='fs-ss-35 fw-800 lh-sm text-uppercase'>
          Caring for Every <span className='text-ss-primary'> Generation, </span> <br/> Every Step of the Way 
            </div>
        </div>
        <div className='ms-auto pe-5 me-5 align-self-center'>
          <Link to="/book-now" className='text-decoration-none shadow-ss-1 py-2 fw-500 btn-primary-secondary border-0 text-white fs-ss-18 px-4 rounded-5'>
            Book Now
          </Link>
        </div>
        </div>
        <div className="row justify-content-evenly py-5">
        <div className="col-21 text-center bg-light rounded-2 py-2 shadow-ss-1 text-uppercase py-5">
      <div className='fw-400 fs-ss-20 lh-sm'>
        <div className='fw-700'>
          Call Us
          <div className='fw-600 fs-ss-16 pt-2'>
            Telephone: <br/>
            <a className='text-decoration-none fw-400 text-black' href="tel:+12567745524"> 256-774-5524 </a>
          </div>
          <div className='fw-600 fs-ss-16 pt-2'>
            Fax: <br/>
            <span className='fw-400 text-black'> 256-774-5524 </span>
          </div>
        </div>

        <div className='fw-700 pt-4'>
          After-Hours Phone <br/>
            <span className='fw-400 text-black fs-ss-16'>
          866-388-0798 
          </span>
        </div>
      </div>
    </div>
    <div className="col-21 text-center bg-green-pattern rounded-2 py-2 shadow-ss-1 text-uppercase py-5 text-white">
      <div className='fw-500 lh-sm'>
        <div className='fw-700 pt-4 fs-ss-30'>
          Operating Hours
        </div>
        <div className='pt-3 fs-ss-16'>
          Monday-Friday:
          <div className='fs-ss-18'>
            8 Am - 5 Pm
          </div>
        </div>
        <div className='pt-3 fs-ss-16'>
          Closed for Lunch
          <div className='fs-ss-18'>
            12 Pm - 1 Pm Daily
          </div>
        </div>
      </div>
    </div>
    <div className="col-21 text-start bg-light rounded-2 py-2 shadow-ss-1 py-5">
      <div className='fw-400 fs-ss-20 lh-sm ps-4'>
        <div className='fw-700 pt-4 text-uppercase'>
         Office Holidays
        </div>
        <div className='pt-2 fs-ss-16 fw-500'>
        <div className='pb-2'> <CircleFill className='fs-ss-7 me-2'/> Memorial Day </div>
        <div className='pb-2'> <CircleFill className='fs-ss-7 me-2'/> Independence Day    </div>
        <div className='pb-2'> <CircleFill className='fs-ss-7 me-2'/> Labor Day  </div>
        <div className='pb-2'> <CircleFill className='fs-ss-7 me-2'/> Thanksgiving Day   </div>
        <div className='pb-2'> <CircleFill className='fs-ss-7 me-2'/> Christmas Day  </div>
        <div className='pb-2'> <CircleFill className='fs-ss-7 me-2'/> New Year's Day  </div>
      </div>
    </div>
    </div>
        </div>
      </section>

      <section className='pt-4 pt-lg-5'/>

      <section className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-10">
            <div className='fs-ss-35 fw-800 lh-sm text-uppercase text-center'>
              Patient Reviews
            </div>
            <div className='fs-ss-16 text-center'>
              Coming Soon
            </div>
          </div>
        </div>
      </section>

      <section className='pt-4 pt-lg-5'/>
    <Footer/>
    </>
  );
}

export default Home;
