import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';

const HealthLinks = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Health Links - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <Footer/>
    </>
  );
}

export default HealthLinks;
