import React from 'react';
import {Routes, Route} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import '../src/assets/css/style.css';

// Pages
import Home from './pages/Home'
import NewPatients from './pages/NewPatients';
import ClinicalTeam from './pages/ClinicalTeam';
import ServicesOffered from './pages/ServicesOffered';
import ContactUs from './pages/ContactUs';
import HealthLinks from './pages/HealthLinks';
import Covid19 from './pages/Covid19';
import PatientPortal from './pages/PatientPortal';

const App = () => {
  return (
    <>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/new-patients" element={<NewPatients/>} />
          <Route path="/clinical-team" element={<ClinicalTeam/>} />
          <Route path="/services-offered" element={<ServicesOffered/>} />
          <Route path="/contact-us" element={<ContactUs/>} />
          <Route path="/health-links" element={<HealthLinks/>} />
          <Route path="/covid-19" element={<Covid19/>} />
          <Route path="/patient-portal" element={<PatientPortal/>} />
        </Routes>
    </>
  );
}

export default App;
