import React from 'react';
import { Link } from "react-router-dom"
import Navbar from 'react-bootstrap/Navbar';
import HeaderLogo from '../../assets/images/home/HeaderLogo.webp';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { GeoAltFill, TelephoneFill } from 'react-bootstrap-icons';
import NavBarLink from './NavBarLink';

const Header = () => {
  return (
    <>
    <section className="container-fluid fs-ss-14 bg-ss-secondary py-1 ps-4 ps-xl-5 pe-3">
      <div className="row">
        <div className="col-12">
          <TelephoneFill className="text-white me-2"/>  <span className="">
            <a href="tel:+12567745524" className='text-decoration-none text-white'>
            256-774-5524
            </a>
          </span>
          <span className='px-3'/>
          <GeoAltFill className="text-white me-2"/>  <span className="">
            <a href="https://www.google.com/maps/place/400+Sun+Temple+Dr,+Madison,+AL+35758,+USA/@34.7131481,-86.739948,17z/data=!3m1!4b1!4m6!3m5!1s0x88626f5f3ba43f51:0xe1a93e8a15d998cd!8m2!3d34.7131481!4d-86.739948!16s%2Fg%2F11rr8dz8jr?entry=ttu" target='_blank' className='text-decoration-none text-white'>
            New Office: 400 Sun Temple Dr, Madison, AL 35758
            </a>
          </span>
        </div>
      </div>
    </section>
    <section className="container-fluid gx-0 ps-4 ps-xl-5 pe-1">
      <Navbar expand="xl" className="">
        <div className='container-fluid gx-0'>
            <Navbar.Brand className='navbar-brand mx-auto'>
              <Link
            className=""
            to="/"
            >
            <img
                src={HeaderLogo}
                alt="Logo"
                className=""
                style={{width:"170px"}}
            />
            </Link>
                
                
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} className='ms-5 border-0 rounded-0 toggle-icon'/>
            </Navbar.Brand>
            <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-xl`}
            aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
            placement="start"
            className="navbar-offcanvas"
            >
            <Offcanvas.Header closeButton className=''>

                <Offcanvas.Title className='' id={`offcanvasNavbarLabel-expand-xl`}>
                    <Link
                    className=""
                    to="/"
                    >
                    <img
                        src={HeaderLogo}
                        alt="Logo"
                        className="w-100"
                    />
                    </Link>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <ul className="navbar-nav ms-xl-3">
              <NavBarLink
              Name="Home"
              Link_To=""
              />
              <NavBarLink
              Name="New Patients"
              Link_To="new-patients"
              />
              <NavBarLink
              Name="Clinical Team"
              Link_To="clinical-team"
              />
              <NavBarLink
              Name="Services Offered"
              Link_To="services-offered"
              />
              <NavBarLink
              Name="Contact Us"
              Link_To="contact-us"
              />
              <NavBarLink
              Name="Health Links"
              Link_To="health-links"
              />
              <NavBarLink
              Name="Covid-19"
              Link_To="covid-19"
              />
              <Link to="/patient-portal" className='text-center mt-4 mt-xl-0 nav-link fw-500 btn-primary-secondary border-0 text-white fs-ss-links px-3 rounded-2'>
              Patient Portal
              </Link>
            </ul>

            </Offcanvas.Body>
            </Navbar.Offcanvas>
            </div>        
            </Navbar>
            </section>
    </>
  )
}

export default Header