import React from 'react';
import {Link} from 'react-router-dom';

const MiddleBanner = (props) => {
  return (
    <>
    <section id='website-responsive' className="container-fluid bg-ss-darkgreen py-4 py-lg-5">
      <div className="row justify-content-center text-center py-lg-5">
        <div className="col-11 col-lg-9">
          <div className='fw-700 text-ss-primary pb-3 pb-lg-4 fs-ss-35'>
            {props.Title}
          </div>
          <div className="text-white pb-3 pb-lg-5 fs-ss-16">
            {props.Para}
          </div>
          <div className='d-block d-lg-flex'>
            <div className='ms-auto'>
            <Link to="/book-now" className='text-decoration-none py-2 fw-500 btn-white-pri border-0 fs-ss-18 px-4 rounded-5'>
                Book Appointment
              </Link>
            </div>
            <div className='mx-2'/>
            <div className='me-auto'>
            <Link to="/book-now" className='text-decoration-none py-2 fw-500 btn-white-pri border-0 fs-ss-18 px-4 rounded-5'>
               Accepting New Patients
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default MiddleBanner;