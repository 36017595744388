import React from 'react';
import {Link} from 'react-router-dom';

const CommonBanner = (props) => {
  return (
    <>
    <section id='website-responsive' className="container-fluid common-banner py-xl-5" style={{backgroundImage:`url(${props.Image})`}}>
      <div className="row justify-content-center align-items-center py-xl-5">
        <div className="col-10 py-5 my-lg-5">
          <div className="lh-sm fw-400 fs-ss-30">
            {props.Title}
          </div>
          <div>

          </div>
          <div className='pt-4'>
          <Link to="/book-now" className='text-decoration-none shadow-ss-1 py-1 fw-500 btn-primary-secondary border-0 text-white fs-ss-22 px-4 rounded-5'>
            Book Now
          </Link>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default CommonBanner