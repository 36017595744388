import React from 'react';
import {Link} from 'react-router-dom';

const SingleService = (props) => {
  return (
    <>
    <div className="col-3 text-center bg-ss-pink py-2 shadow-ss-1" style={{borderTopLeftRadius:"40px"}}>
      <img className='w-100' src={props.Image} alt={props.Alt} />
      <div className='fw-600 pt-3 fs-ss-18 lh-sm'>
        {props.Name}
      </div>
    </div>
    </>
  )
}

export default SingleService